const createText = (label, width = 100, required = true, value = '', padding = false) => (
   {label, required, width, value, padding, type: 'text', state: value ? value : ''}
);

const createDropDown = (label, width = 100, required = true, value = [], padding = false, state = '') => (
   {label, required, width, value, padding, type: 'dropdown', state}
);

const createList = (label, value = [], multiple = false, state, required = true) => (
   {label, required, width: 100, value, padding: false, type: multiple ? 'multiple' : 'list', state}
);

const fields = {
   antrag: [
      createText('Name oder Bezeichnung'),
      createText('Straße und Hausnummer'),
      createText('Land', 100, true, 'Deutschland'),
      createText('PLZ', 20, true, '', true),
      createText('Ort', 80),
      createText('E-Mail Adresse'),
      createText('Telefonnummer', 50, false, '', true),
      createText('Bearbeiter', 50, false),
   ],
   anschrift: [
      createText('Name oder Bezeichnung', 100, "validateBilling", ''),
      createText('Straße und Hausnummer', 100, "validateBilling"),
      createText('Land', 100, "validateBilling", 'Deutschland'),
      createText('PLZ', 20, "validateBilling", '', true),
      createText('Ort', 80, "validateBilling"),
      createText('E-Mail Adresse', 100,'validateBilling',''),
   ],
   rechnung: [
      createList('Rechnung an', ['Antragssteller', 'Lieferanschrift'], false, 'Antragssteller')
   ],
   gebiet: [
      createText('Gemeinde', 100, false),
      createText('Gemarkung', 100, false),
      createText('Flurstücksnummer', 100, false),
      createText('Lagebezeichnung', 100, false),
   ],
   liegenschaft: [
      createList('Karte', ['farbig (Standardausgabe)', 'schwarz-weiß', 'Katasternachweis nach §12 Abs.2 SächsVermKatGDVO',
         'Bodenschätzung farbig', 'Bodenschätzung schwarz-weiß','keine Bestellung'], false, ''),
      createText('Maßstab', 50, true, '1 : 1 000', true),
      createDropDown('Format', 50, true, ['DIN A4 (21,0 x 29,7 cm)', 'DIN A3 (29,7 x 42,0 cm)', 'DIN A2 (42,0 x 59,4 cm)', 'DIN A1 (59,4 x 84,1 cm)', 'DIN A0 (84,1 x 118,9 cm'], false, 'DIN A4 (21,0 x 29,7 cm)'),
   ],
   eigentumsnachweis: [
      createList('', ['Flurstücksnachweis', 'Flurstücks- und Eigentumsnachweis', 'Grundstücksnachweis', 'Bestandsnachweis', 'Flurstücks- und Eigentumsnachweis mit Angaben zu benachbarten Flurstücken'], true, [], false)
   ],
   darlegung: [
      createList('Antragsteller', ['ist Eigentümer', 'ist vom Flurstückeigentümer bevollmächtigt', 'ist Behörde', 'ist Gemeinde',
         'hat ein sonstiges berechtigtes Interesse'], false, ''),
      createText('berechtigtes Interesse', 100, "validateInterest")
   ],
   versandart: [
      createList('Versandart', ['Ausdruck (Versand per Post)', 'PDF-Datei (Versand per E-Mail)'], false, '')
   ]
};

const validateRequired = (state, element) => {
   const requiredFunc = element.required;
   if (requiredFunc === 'validateInterest') {
      // does state in darlegung contains "hat ein sonstiges berechtigtes Interesse"
      return state.darlegung[0].state.indexOf('hat ein sonstiges berechtigtes Interesse') !== -1;
   }

   if (requiredFunc === 'validateBilling') {
      // check state in rechnung for "lieferanschrift"
      if (state.rechnung[0].state === 'Lieferanschrift') {
         if (element.label === 'E-Mail Adresse') {
            return false;
         }
         return true;
      }
   }

   return false; // fallback
};

const send = (state, callback) => {
   const json = [];

   const mapper = {
      antrag: 'Antragsteller',
      anschrift: 'Lieferanschrift',
      rechnung: 'Rechnungsstellung',
      gebiet: 'Gebietsabgrenzung',
      liegenschaft: 'Bestellung eines Auszugs aus der Liegenschaftskarte',
      eigentumsnachweis: 'Bestellung von Flurstücks- und Eigentumsnachweisen',
      darlegung: 'Darlegung des berechtigten Interesses bei Eigentumsnachweisen',
      versandart: 'Versandart'
   };

   for (let key in state) {

      const group = {
         name: mapper[key],
         data: []
      };

      state[key].forEach(element => {
         if (element.state.length > 0) {
            group.data.push({
               label: element.label,
               value: element.state
            });
         }
      });

      json.push(group);
   }

   const body = JSON.stringify({
      list: json,
      name: state.antrag[0].state,
      replyTo: state.antrag[5].state
   });

   fetch($Fields.BASEURL + 'apply.php', {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body
   })
      .then(res => res.json())
      .then(() => callback(true))
      .catch(() => callback(false));
};

const missingSections = (state) => {
   // iterate over all sections and check the required field
   const missing = [];
   for (let key in state) {
      if (!state[key].every(ele => {
         if (typeof ele.required === 'string') {
            const req = $Fields.validateRequired(state, ele);
            return (req && ele.state.length > 0) || !req;
         } else {
            return (ele.required && ele.state.length > 0) || !ele.required;
         }
      })) {
         missing.push(key);
      }
   }
   return missing;
}

const canSend = (state) => {
   return missingSections(state).length === 0;
};

export const $Fields = {
   fields,
   send,
   canSend,
   missingSections,
   validateRequired,
   BASEURL: 'api/'
}