import './welcome.scss';
import {UserInput} from "./components/userinput";
import {useState} from "react";
import {$Fields} from "./models/fields";

export const Welcome = ({success}) => {
   const [credentials, setCredentials] = useState({username: '', pass: ''});
   const [error, setError] = useState('')

   const tryToSignIn = () => {
      fetch($Fields.BASEURL + 'apply.php?welcome', {
         method: 'post',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(credentials)
      })
         .then(res => res.json())
         .then(json => {
            if ("error" in json) {
               setError(json.error);
            } else {
               success(json);
            }
         });
   };

   return (
      <div className="welcome">
         <div className="bg"/>
         <div className="overlay"/>
         <div className="welcome-data">

            <h1>Herzlich Willkommen</h1>
            <p>Dieser Bereich ist geschützt. </p>

            <UserInput label={'Benutzername'} required value={credentials.username}
                       onChange={(ev) => {
                          setCredentials({...credentials, username: ev.target.value});
                          setError('');
                       }}/>
            <UserInput label={'Passwort'} showInput={false} required value={credentials.pass}
                       onChange={(ev) => {
                          setCredentials({...credentials, pass: ev.target.value});
                          setError('');
                       }}/>


            {error && <div className="error">{error}</div>}
            <button onClick={tryToSignIn}>Anmelden</button>
         </div>
      </div>
   );
};