import './userinput.scss';

export const UserInput = ({
                             label,
                             value = '',
                             required = false,
                             showInput = true,
                             onChange = () => null
                          }) => (
   <div className="userinput">
      <label>{label} {required ? '(*)' : ''}</label>
      <input type={showInput ? 'text' : 'password'} value={value} onChange={onChange}/>
   </div>
);