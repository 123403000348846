import './App.scss';
import {$Fields} from "./models/fields";
import {useEffect, useState} from "react";
import {ButtonList} from "./components/buttonlist";
import {UserInput} from "./components/userinput";
import {Dropdown} from "./components/dropdown";
import {Welcome} from "./welcome";

const App = () => {

   const [state, setState] = useState(JSON.parse(JSON.stringify($Fields.fields)));
   const [userData, setUserData] = useState(null);

   const updateFields = () => {
      if (userData) {
         const newState = {...state};

         userData.forEach((value, index) => {
            newState.antrag[index].state = value;
         });
         setState(newState);
      }
   };

   useEffect(updateFields, [userData]);

   if (userData === null) {
      return <Welcome success={(body) => setUserData(body)}/>;
   }

   const renderInputElement = (title, attribute, fld, index) => {
      if (fld.type === 'text') {
         let required = fld.required;
         if (typeof fld.required === 'string') {
            required = $Fields.validateRequired(state, fld)
         }
         return (<UserInput
            label={fld.label}
            value={fld.state}
            required={required}
            onChange={(ev) => {
               const section = [...state[attribute]];
               section[index].state = ev.target.value;
               setState({...state, [attribute]: section})
            }}/>);
      } else if (fld.type === 'list' || fld.type === 'multiple') {
         return (
            <>
               {fld.type === 'multiple' && <div className="clear" onClick={() => {
                  const section = [...state[attribute]];
                  section[index].state = [];
                  setState({...state, [attribute]: section})
               }}>Alles abwählen</div> }
            <ButtonList
               list={fld.value}
               selected={fld.state}
               multiple={fld.type === 'multiple'}
               onSelect={(fl) => {
                  const section = [...state[attribute]];
                  section[index].state = fl;
                  setState({...state, [attribute]: section})
               }}/>
               </>
         );
      } else if (fld.type === 'dropdown') {
         return (<Dropdown
            label={fld.label}
            list={fld.value}
            value={fld.state}
            required={fld.required}
            onChange={(value) => {
               const section = [...state[attribute]];
               section[index].state = value;
               setState({...state, [attribute]: section})
            }}/>);
      }
      return null;
   };

   const renderArea = (title, description, attribute) => {
      const isMissing = $Fields.missingSections(state).indexOf(attribute) !== -1;
      return (
         <div className="pb">
            <h3>{title}</h3>
            <div className="description">{description}</div>
            <div className="row">
               {
                  state[attribute].map((fld, index) => (
                     <div
                        key={title + '-' + index}
                        className="cell"
                        style={{width: fld.width + '%', paddingRight: fld.padding ? 8 : 0}}>
                        {renderInputElement(title, attribute, fld, index)}
                     </div>
                  ))
               }
            </div>
            {isMissing && (<p className="missing">
               <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <g transform="scale(.75)">
                     <g strokeLinecap="round" stroke="#DF5613" fill="none" strokeLinejoin="round">
                        <path
                           d="M23 11.811v-.001c.01 6.11-4.89 11.1-11 11.18v0c-5.99.09-10.91-4.69-11-10.68 -.01-.05-.01-.1-.01-.14l-.001 0c-.02-6.12 4.88-11.11 11-11.192v0c5.98-.09 10.9 4.68 10.99 10.67 0 .04 0 .09 0 .13Z"/>
                        <path d="M12 14.01l0-7"/>
                        <path
                           d="M11.991 16.005l-.001 0c-.14 0-.25.11-.25.24 0 0 0 0 0 0v0c0 .13.11.24.25.24v0 -.001c.13-.01.24-.12.24-.26 -.01-.01-.01-.01-.01-.01v0c-.01-.14-.12-.25-.25-.25h-.01"/>
                     </g>
                  </g>
               </svg>
               Dieser Bereich enthält unvollständige Angaben
            </p>)}
         </div>
      );
   };

   const handleResponse = (success) => {
      if (success) {
         setState(JSON.parse(JSON.stringify($Fields.fields)));
         //NotificationManager.success('EMail wurde erfolgreich versendet', 'Datenlieferung');
      } else {
         //NotificationManager.error('Fehler beim versenden der EMail', 'Datenlieferung');
      }
   };

   return (
      <div className="application">
         <h1 className="alignRight">Bestellung von Auszügen aus<br/>dem Liegenschaftskataster
            <small>Liegenschaftskarte
               Flurstücks- und Eigentumsnachweis</small></h1>

         {renderArea('Antragsteller', '', 'antrag')}
         {renderArea('Lieferanschrift', 'wenn nicht mit Antragsteller identisch', 'anschrift')}
         {renderArea('Rechnung', 'An wen soll die Rechnung verschickt werden?', 'rechnung')}
         {renderArea('Gebietsabgrenzung', '', 'gebiet')}
         {renderArea('Bestellung eines Auszugs aus der Liegenschaftskarte', '', 'liegenschaft')}
         {renderArea('Bestellung von Flurstücks- und Eigentumsnachweisen', '', 'eigentumsnachweis')}
         {renderArea('Darlegung des berechtigten Interesses bei Eigentumsnachweisen', 'Der Antragsteller...', 'darlegung')}
         {renderArea('Versandart', '', 'versandart')}

         <div style={{paddingBottom: '2rem'}}>&nbsp;</div>

         <div className="submit">
            <div>
               Mit Drücken der Schaltfläche <strong>Zahlungspflichtig bestellen</strong> aktivieren Sie den Bestellvorgang über das Internet.<br/>
               Alle Felder mit einem * bitte unbedingt ausfüllen. Zutreffendes bitte ankreuzen bzw. ausfüllen.
            </div>
            <button onClick={() => $Fields.send(state, handleResponse)}
                    disabled={!$Fields.canSend(state)}>Zahlungspflichtig bestellen
            </button>
         </div>
      </div>
   );
}

export default App;