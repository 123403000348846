import './dropdown.scss';
import {useRef, useState} from "react";

export const Dropdown = ({label, value = '', list = [], required = false, onChange = () => null}) => {
   const [open, setOpen] = useState(false);
   const ref = useRef();

   const getWidth = () => {
      if (ref?.current) {
         return ref.current.getBoundingClientRect().width;
      }
      return 'auto';
   }
   return (
      <>
         <div className="dropdown" onMouseEnter={() => setOpen(true)} ref={ref}>
            <label>{label} {required ? '(*)' : ''}</label>
            <input type="text" disabled value={value}/>
         </div>
         {open && <div className="dropdown-menu" style={{width: getWidth()}} onMouseLeave={()=>setOpen(false)}>
            {list.map(item => (<div key={item} className="dropdown-item" onClick={() => {
               onChange(item);
               setOpen(false);
            }}>{item}</div>))}
         </div>}
      </>
   );
}