import './buttonlist.scss';

export const ButtonList = ({
                              list = [],
                              onSelect = () => null,
                              selected,
                              multiple = false
                           }) => {

   const isActive = (item) => {
      if (multiple && selected.indexOf(item)!==-1) {
         return true;
      }
      if (!multiple && selected === item) {
         return true;
      }
      return false;
   };

   return (
      <div className="row buttonlist">
         {
            list.map(item => (<button
               key={item.replace(/ /g, '')}
               className={isActive(item) ? 'highlight' : ''}
               onClick={() => {
                  if (multiple) {
                     const list = [...selected];
                     const index = list.indexOf(item);
                     if (index === -1) {
                        list.push(item);
                     } else {
                        list.splice(index,1);
                     }
                     onSelect(list);
                  } else {
                     onSelect(item)
                  }

               }}>{item}</button>))
         }
      </div>
   )
}